.filter-btn {
    margin-right: 10px;
}

.filter {
    margin-top: 10px;
    display: flex;
}

.input-group {
    width: 25%;
    height: 40px;
    /* display: none; */
}

@media screen and (max-width: 768px) {
    .filter {
        flex-direction: column;
    }

    .input-group {
        width: 100%;
    }
}